import { NextPage } from 'next';
import NextErrorComponent from 'next/error';
import React from 'react';
import { useTranslation } from 'react-i18next';

import MainHeader from '@/components/common/mainHeader/MainHeader';
import { DefaultLayout } from '@/utils/layout';
import { TypePageHeader } from '@/utils/page';
import { TypeColor, TypeHeaderImgDisplay } from '@/utils/prismic';
import * as Sentry from '@sentry/nextjs';

import Error from '../components/app/error/Error';
import PageMeta from '../components/app/pageMeta/PageMeta';

export type ErrorProps = {
  statusCode?: number;
};

export const CustomError: NextPage<ErrorProps> = ({ statusCode }) => {
  const { t } = useTranslation();
  const label = statusCode
    ? t('errorServer', { statusCode })
    : t('errorClient');
  const header: TypePageHeader = {
    hasSearchField: false,
    visualDisplayMode: TypeHeaderImgDisplay.FULL,
    bgColor: TypeColor.GREEN,
    cta: [],
    pressEnabled: false,
    title: t('errorNotFoundTitle'),
    subTitle: label
  };

  return (
    <PageMeta title={label} isNotIndexable={true}>
      <DefaultLayout isTransparent>
        <MainHeader headerData={header} showBreadcrumb={false} />
        <Error message={t('errorNotFoundMessage')} />
      </DefaultLayout>
    </PageMeta>
  );
};

CustomError.getInitialProps = async contextData => {
  await Sentry.captureUnderscoreErrorException(contextData);

  return NextErrorComponent.getInitialProps(contextData);
};

export default CustomError;
